import React, { useState, useEffect } from "react";
import {post} from "../util/util.js"
import {Card, Container, Badge, Tab, Tabs} from "react-bootstrap"
import moment from "moment"
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' ,
      }
    },
  };

export default function Bet(props){
    const [betHistory, setBetHistory] = useState([])
    const [summary, setSummary] = useState([])
    const [matches, setMatches] = useState([])
    const [results, setResults] = useState([])
    const [chartData, setChartData] = useState([])
    const [betResult, setBetResult] = useState([])

    const fetchDate = ()=>{
        post('/bet/getHistory',{},d=>{
            setBetHistory(d.data||[])
            setMatches(d.matches||[])
            setResults(d.results||[])
            setChartData(d.chartData||{})
            setSummary(d.winInfo)
            setBetResult(d.betResult)
        })
    }

    useEffect(()=>{
        fetchDate()
        setInterval(()=>{
            fetchDate()
        },10000)
      },[])

    return (
        <Container style={{textAlign:'center'}}>
        <Badge bg="secondary">(贏 / 總)：{summary.win} / {summary.ttl}</Badge>
        <Badge bg="info">(Bet/Win)：{(summary.betAmt||0).toLocaleString('en-US',{style:'currency', currency: 'USD'})} / {(summary.winAmt||0).toLocaleString('en-US',{style:'currency', currency: 'USD'})}</Badge>
        <Badge bg="primary">{summary.time}</Badge>

        <Tabs
            defaultActiveKey="betHistory"
            id="uncontrolled-tab-example"

        >   

            <Tab eventKey="betHistory" title="Bet">
                {betHistory.map((t, i)=>{
                        return <Card key={i} border={t.winAmt>0?'success':'light'}>
                        <Card.Header style={{'background-color':t.winAmt>0?'#7FFF00':'#F0FFFF'}}>
                            <Card.Title>{t.frontEndID} {t.h +':' + t.a}  </Card.Title>
                            <Card.Title>({t.hr +':' + t.ar}) / Result:[{t.result}]</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>{t.createDate} 余額:{(t.afterBetTotal).toLocaleString('en-US',{style:'currency', currency: 'USD'})}</Card.Text>
                            <Card.Text>比賽時間:{t.runTime} 賠率：{t.odds} 下注金額:{t.betAmt} 贏:{t.winAmt} [{t.type}] </Card.Text>
                        </Card.Body>
                    </Card>
                    })}
            </Tab>
            <Tab eventKey="match" title="Match">
                {matches.map((t, i)=>{
                        return <Card key={i} border={t.runTime>=0?'success':'light'}>
                        <Card.Header style={{'background-color':t.runTime>0?'#7FFF00':'#F0FFFF'}} >
                            <Card.Title as="h8">{t.frontEndId} ({t.start})</Card.Title><br/>
                            <Card.Title as="h8">{t.home +':' + t.away} ({t.homeR +':' + t.awayR}) / {t.runTime>=0?t.runTime:'-'} / {t.odds}</Card.Title>
                        </Card.Header>
                    </Card>
                    })}
            </Tab>
            <Tab eventKey="result" title="Match Result">
                {results.map((t, i)=>{
                        return <Card key={i}>
                        <Card.Header>
                            <Card.Title as="h8">{t.frontEndId} ({t.start})</Card.Title><Badge bg={t.color}>{t.status}</Badge><br/>
                            <Card.Title as="h8">{t.home +':' + t.away} ({t.homeR +':' + t.awayR}) </Card.Title>
                        </Card.Header>
                    </Card>
                    })}
            </Tab>
            <Tab eventKey="betresult" title="Bet Result">
                {betResult.map((t, i)=>{
                        return <Card key={i}>
                        <Card.Header>
                            <Card.Text >{t.dtl}</Card.Text><br/>
                            <Card.Text >{t.tranTime} out:${t.out} in:${t.in} </Card.Text>
                        </Card.Header>
                    </Card>
                    })}
            </Tab>
            <Tab eventKey="chart" title="Chart">
            <Line options={options} data={{
                labels:chartData.labels||[],
                datasets: [
                    {
                        label: 'Balance',
                        data: chartData.data||[],
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                      }
                ]                    
            }
            } />
            </Tab>

        </Tabs>
                
                
        </Container>
    )
}