import React, { useState, useEffect } from "react";
import {post, alert, prompt} from "../util/util.js"
import {Form, Button,Accordion, ListGroup, Row, Col} from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus} from '@fortawesome/free-solid-svg-icons'

export default function UserInfo(props){
    const [info,setInfo] = useState({})
    const [houseList,setHouseList] = useState([])

    useEffect(()=>{
        post('/userInfo/getDetails',{},d=>{
            setInfo(d.userInfo)
            setHouseList(d.houseList)
          })
      },[])

    const handleSubmit = (event)=>{
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
       
        if (form.checkValidity() === true) {

            if(info.pwd!='' && info.pwd!=info.repwd){
                alert('两次密码不相同')
            }else 
            post('/userInfo/save',{info}, (d)=>{
                console.log(d)
                if(d.ok) {
                    alert('保存成功')
                    const newList = houseList.filter(t=>t.id!==info.id)
                    setHouseList(newList)
                }
                else {
                    alert(`${d.msg||'保存失败，请再尝试'}`)
                }
            })

        }
    }

    const changeText = (e)=>{        
        if(e.target.id){
            const newInfo = {...info}
            newInfo[e.target.id]=e.target.value
           setInfo(newInfo)
        }
    }

    const changeHouseField = (e, idx)=>{
        if(e.target.id){
            const newList = [...houseList]
            newList[idx][e.target.id]=e.target.value
            setHouseList(newList)
        }
    }

    const addHouse = () =>{
        const newList = [...houseList]
        newList.push({id:new Date().getTime(), no:'', isNew:true})
        setHouseList(newList)
    }

    const removeHouse = (r)=>{
        prompt((<>是否需要刪除此記錄？</>),'',(ok)=>{
            if(ok) {
                if(r.isNew){
                    const newList = houseList.filter(t=>t.id!==r.id)
                    setHouseList(newList)
                } else {
                    post('/userInfo/removeHouse',{id:r.id},(d)=>{
                        if(d.ok===true){
                            const newList = houseList.filter(t=>t.id!==r.id)
                            setHouseList(newList)
                            alert('刪除成功')
                        }
                        else
                            alert('未能刪除，请稍后尝试') 
                    })
                }
            }
        })
    }

    const saveHouse = (r)=>{
        post('/userInfo/saveHouse',{data:r},(d)=>{
            if(d.ok===true){
                const newList = [...houseList]
                const idx = newList.findIndex(t=>t.id===r.id)
                newList[idx]=d.data
                setHouseList(newList)
                alert('保存成功')
            }
            else
                alert('未能保存，请稍后尝试') 
        })
    }

    return (
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header>开发中。。。</Accordion.Header>
                <Accordion.Body>
                
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>开发中。。。</Accordion.Header>
                <Accordion.Body>
                    
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>


        
    )
}