/*
Cheat Sheet for Updating Objects and Arrays in React State
https://dev.to/andyrewlee/cheat-sheet-for-updating-objects-and-arrays-in-react-state-48np 


How to Build Dynamic Forms in React
https://www.freecodecamp.org/news/build-dynamic-forms-in-react/
*/
import React, {useEffect, useState} from "react";
import {post, popup, alert} from "../util/util.js"
import {Form, Row, Col, Card, Button, Badge, ListGroup} from "react-bootstrap"
import moment from "moment"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFaucetDrip, faBolt, faPen, faHouse} from '@fortawesome/free-solid-svg-icons'
import { lazy } from 'react'


export default function House(props){
  const [house, setHouse] = useState()
  const [houseList, setHouseList] = useState([])
  const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'))
  const [weList, setWeList] = useState([])
  const [userType, setUserType] = useState('')
  const [houseObj, setHouseObj]=useState({})

  const [player, setPlayer] = useState([])
  
  useEffect(()=>{
    post('/player/getPlayerList',{},d=>{
      setPlayer(d.data)
    })
    /*
    post('/house/getHouseList',{},d=>{
      setHouseList(d.data)
    })*/
  },[])

  const changeHouse = (e)=>{
    setHouse(e.target.value)
    let obj = houseList.find(t=>t.value==e.target.value)
      if(obj){
        setHouseObj(obj)
        setUserType(obj.Type)
      }else {
        setUserType('')
      }
      
    post('/house/getWE',{house:e.target.value}, d=>{
      setWeList(d.data)
    });
  }

  const showDetails = (data, idx) =>{
    const Room = lazy(() => import('./room'))
    popup(<Room data={data} userType={userType} cb={(d)=>{
      const newWeList = [...weList]
      newWeList[idx] = d
      setWeList(newWeList)
    }}/>, `編輯 ${data.name} 資料`)
  }

  const changeText = (idx, tp, e)=>{
    const newWEList = [...weList]
    newWEList[idx][tp] = e.target.value
    setWeList(newWEList)
  }

  const save = (idx) =>{
    post('house/save',{...weList[idx], currentDate, house}, d=>{
      
      if(d.error){
        alert(d.error)
      } else{
        alert(`${weList[idx].no} 保存成功`)
        const newWEList = [...weList]
        newWEList[idx]=d.data
        setWeList(newWEList)
      }
    })
  }

  const showHourse = (data) =>{
    const HouseDetail = lazy(() => import('./houseDetail'))
    popup(<HouseDetail data={data} userType={userType} cb={(d)=>{
    }}/>, `编辑 ${data.label} 资料`)
  }

  const addRoom = (d)=>{
    const newWeList = [...weList]
      newWeList.push(d)
      setWeList(newWeList)
  }

  const newRoom = (data) =>{
    const AddRoom = lazy(() => import('./addRoom'))
    popup(<AddRoom data={data} cb={addRoom}/>, `新加单位资料`)
  }

  const bill = (data) =>{
    const CheckWE = lazy(() => import('./checkWE'))
    popup(<CheckWE data={data, weList}/>, `檢查水電抄表`)
  }

  const orderRoom = (data) =>{
    const OrderRoom = lazy(() => import('./orderRoom'))
    popup(<OrderRoom data={data} />, `單位排序`)
  }

  return (
    <Form>
            {/*<Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>选择房屋</Form.Label>
                  <Form.Select aria-label="Default select example" onChange={(e)=>changeHouse(e)} value={house}>
                  {houseList.map((t, i)=>{
                    return <option value={t.value} key={i}>{t.label}</option>
                  })}
                </Form.Select>
                {userType=='OWNER'&&<Button variant="primary" onClick={(e)=>showHourse(houseObj)}>编辑房屋资料</Button>}
                {userType=='OWNER'&&<Button variant="primary" onClick={(e)=>newRoom(houseObj)}>新加单位</Button>}
                <Button variant="primary" onClick={(e)=>bill(houseObj)}>檢查水電抄表</Button>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group >
                  <Form.Label>抄表日期</Form.Label>
                  <Form.Control type="date" name="dob" placeholder="抄表日期" onChange={(e)=>setCurrentDate(e.target.value)} value = {currentDate}/>
                </Form.Group>
              </Col>
            </Row>
                <br/>*/}
            <Row xs={1} md={4} className="g-4">
                {player.map((t, idx)=>{
                  console.log(t)

                  return (
                    <Col key={idx}>
                    <Card>
                      <Card.Img variant="top" src={`getImg/${t.id}`} style={{maxHeight:'240px', maxWidth:'240px'}}/>
                      <Card.Body>
                      <Card.Title>{t.name}</Card.Title>
                      <ListGroup className="list-group-flush">
                        <ListGroup.Item> <Card.Subtitle>年龄：{t.age}</Card.Subtitle></ListGroup.Item>
                        <ListGroup.Item><Card.Subtitle>出生日期:{t.birth}</Card.Subtitle></ListGroup.Item>
                        <ListGroup.Item><Card.Subtitle>积分: {t.score}</Card.Subtitle></ListGroup.Item>
                        <ListGroup.Item><Card.Subtitle>打法: -- </Card.Subtitle></ListGroup.Item>
                      </ListGroup>                        
                        <Card.Text>
                          This is a longer card with supporting text below as a natural
                          lead-in to additional content. This content is a little bit
                          longer.
                        </Card.Text>

        <Card.Link href="#">训练计划</Card.Link>
        <Card.Link href="#">视频记录</Card.Link>

                      </Card.Body>
                    </Card>
                  </Col>
                  )
                })}
            </Row>
          </Form>
  )
}
