/*
Cheat Sheet for Updating Objects and Arrays in React State
https://dev.to/andyrewlee/cheat-sheet-for-updating-objects-and-arrays-in-react-state-48np 


How to Build Dynamic Forms in React
https://www.freecodecamp.org/news/build-dynamic-forms-in-react/
*/
import React, { useState, useEffect } from "react";
import {post, alert} from "../util/util.js"
import {Form, Row, Col, Button, InputGroup, Carousel, ButtonGroup} from "react-bootstrap"
import moment from "moment"


export default function Bill(props){
  const years = [2022,2023,2024,2025,2026,2027,2028,2029,2030,2031,2032]
  const month = [1,2,3,4,5,6,7,8,9,10,11,12]
  
  const [report, setReport] =useState('')
  const [houseList, setHouseList] = useState([])
  const [currentMonth, setCurrentMonth] = useState(moment().month()+1)
  const [currentYear, setCurrentYear] = useState(moment().year())
  const [house, setHouse] = useState()
  const [rptImages, setRptImages] = useState([])
  const [reportList, setReportList] = useState([])
  const [selectRptList, setSelectRptList] = useState([])
  const [startPreview, setStartPreview] = useState(false)

  useEffect(()=>{
    post('/bill/getHouseList',{},d=>{
      setHouseList(d.data)
      setReportList(d.reportList)
    })
  },[])

  const changeHouse = (e, v)=>{
    setHouse(e.target.value)
    let obj = houseList.find(t=>t.value==e.target.value)
      if(obj){
        const rptl = reportList.filter(t=>t.acl.indexOf(obj.Type)>=0)
        setSelectRptList(rptl||[])
        setReport(rptl[0]?rptl[0].value:'')
      }else {
        setSelectRptList([])
        setReport('')
      }
  }

  const changeReport = (e, v)=>{
    setReport(e.target.value)
  }

  const changeDate = (tp, e)=>{
      if(tp==='years')
        setCurrentYear(e.target.value)
      else 
        setCurrentMonth(e.target.value)
  }

  const print = (tp)=>{

      if(moment().set('year',currentYear).set('month', currentMonth-1).set('date',1).isBefore(moment().set('date',1), 'month')){
        alert('不允许打印旧单，请选择未来的日期')
        return false
      }

      if(tp=='p') {
        setRptImages([])
        setStartPreview(false)    
      } 

      post('/bill/print',{house, currentYear, currentMonth, report, tp}, d=>{
        if(d.error)
          alert(d.error)
        else {
          if(tp=='d')
            window.open(window.URL.createObjectURL(d));
          else {
            if(!d || !d.images || d.images.length==0 )
              alert('系统繁忙，请再尝试')
            setRptImages(d.images)
            setStartPreview(true)    
          }
        }
      }, true, 2000);
  }

  return (
    <Form>
      <Row>
        开发中。。。
      </Row>
    </Form>
)
}
