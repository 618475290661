import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import './index.css';
import App from './App';
import Player from "./screens/player";
import Bill from "./screens/bill";
import Login from "./screens/login";
import SystemLog from "./screens/systemLog";
import Registry from "./screens/registry";
import reportWebVitals from './reportWebVitals';

import UserInfo from "./screens/userInfo";
import Bet from "./screens/bet"

import {useAuth, AuthProvider} from "./components/useAuth";
import { ProtectedRoute } from "./components/protectedRoute";
import {ProtectedLayout} from "./components/protectedLayout";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  //<React.StrictMode>
      <BrowserRouter>
        <AuthProvider>
        <Routes >
            <Route element={<ProtectedLayout />}>
              <Route exact path="/" element={<App />}>
                <Route path="player" element={<Player/>} />
                <Route path="bill" element={<Bill />} />
                <Route path="userInfo" element={<UserInfo />} />
                <Route path="systemLog" element={<SystemLog />} />
              </Route>
            </Route>
          <Route exact path="/login" element={<Login/>} />
          <Route exact path="/registry" element={<Registry />} />
          <Route exact path="/bet" element={<Bet/>} />
        </Routes>
        </AuthProvider>
      </BrowserRouter>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
