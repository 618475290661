import React, {useState} from "react";
import {post, popup} from "../util/util.js"
import {Form, Container, Button, Badge} from "react-bootstrap"
import "./login.css"
import { useNavigate, useLocation} from "react-router-dom";
import { useAuth } from "../components/useAuth.js";
import { lazy } from 'react'

export default function Login(props){
    const [info, setInfo] = useState({})
    const {login} = useAuth()
    const navigate = useNavigate();
    const { state } = useLocation();
    const changeText = (e)=>{        
        if(e.target.id){
            const newInfo = {...info}
            newInfo[e.target.id]=e.target.value
           setInfo(newInfo)
        }
    }

    const handleSubmit = (event)=>{
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
       
        if (form.checkValidity() === true) {
            post('/login/login',{userId:info.userId, pwd:info.pwd}, (d)=>{
                if(d.ok) {
                    login(d.data)
                    navigate(`/player`)
                }
                else {
                    navigate(`/login`, {state:{msg:d.msg}})
                }
            })
        }
    }

    const help = ()=>{
        const Help = lazy(() => import('./help'))
        popup(<Help/>, `幫助提示`)
    }

    const clickRegistry = ()=>{
        const Registry = lazy(() => import('./registry'))
        popup(<Registry cb={login}/>, `新用户注册`)
    }

    return (
        <Container className="login-container">
            <div className="form--user__icon">
                <div className="icon--img">
                    <img alt="LOGO" src="../images/zl.png"  style={{"opacity": 0.5,"width":"100px","height":"100px"}}/>
                </div>
            </div>
            <h4 className="text-center">圳力技术分析系统1.0</h4>
            <Form  onSubmit={(e)=>handleSubmit(e)}>
                <Form.Group>
                    <Form.Label > <span className="glyphicon glyphicon-user"></span> 电话号码:</Form.Label>
                    <Form.Control type="number" id="userId" placeholder="电话号码" name="userId" required onChange={(e)=>changeText(e)}></Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label > <span className="glyphicon glyphicon-lock"></span> 密码:</Form.Label>
                    <Form.Control type="password"  id="pwd" placeholder="密码" name="pwd" required  onChange={(e)=>changeText(e)}></Form.Control>
                </Form.Group>
                <br/>
                <div className="d-grid gap-2">
                    <Button type="submit" variant="primary" >登入</Button>
                    {/*<Button onClick={clickRegistry} variant="primary" >注册</Button>*/}
                </div>
            </Form>
            
            <hr/>
            {/*
            <strong style={{color:"red"}}>{state?(state.msg||''):''}</strong><h3 style={{float:"right"}} onClick={help}><Badge bg="secondary">怎样使用？</Badge></h3>
            -->*/}
        </Container>
      )
}